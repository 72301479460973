import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import CarouselCard from '../../_components/CarouselCard';
import CarouselCardWelcome from '../../_components/CarouselCardWelcome';
// import flowLogo from '../../../../Flow_Logo.png'
// import cadenceTutorialsImage from '../../../../images/cadence_tutorials.png'
// import openCadenceImage from '../../../../images/open_cadence.png'
import flowfund from '../../../../images/flowfund.png'
import welcome from '../../../../images/welcome.png'
import buildonflow from '../../../../images/buildonflow.png'

// import { text } from '@fortawesome/fontawesome-svg-core';

function LearningCadence(props) {

    const LCobjectDesc = [
        {
            "Description": "FlowToMarket is a resource hub for marketing & growing your NFT project on Flow. Find communities to join, marketing best practices, and more!",
            "Link": "",
            "Title": "Welcome to FlowToMarket!",
            "isVideo": false
        },
        
        {
            "Description": "If you’re looking for resources and how to build your own dapp yourself on Flow, check out Flow Quickstart for more development support.",
            "Link": "https://www.flowquickstart.com/",
            "Title": "Build on Flow Essentials",
            "isVideo": false
        },
        {
            "Description": "If you need to get funding for your development, check out the various Flow ecosystem support programs for projects built on Flow.",
            "Link": "https://flow.com/ecosystemsupport",
            "Title": "Flow Ecosystem Financial Support",
            "isVideo": false
        },
        // {
        //     "Description": "Learn all about the NFT Standard in great and easy to digest detail with Andrea and Jacob. After this video you should know all about how the NFT Standard works on Flow, and how to better create your smart contracts.",
        //     "Link": "https://www.youtube.com/watch?v=5v2hf0gOHv8",
        //     "Title": "Understanding the NFT Standard",
        //     "isVideo": true
        // },
        // {
        //     "Description": "Get comfortable with the Metadata Standard on Flow and learn from two of the best in the game with Jacob and Bjartek. The Metadata standard will help make your NFT's metadata accessible to all projects on Flow that are looking to use the views available.",
        //     "Link": "https://www.youtube.com/watch?v=rgJRTozG3cw",
        //     "Title": "The Metadata Standard with Jacob and Bjartek",
        //     "isVideo": true
        // },
        // {
        //     "Description": "Play around with real production grade cadence code and tinker with it in the playground. A list of high-quality Cadence code to help you learn and implement your smart-contract ideas.",
        //     "Link": "https://open-cadence.onflow.org/",
        //     "Title": "Open Cadence",
        //     "isVideo": false
        // },
        // {
        //     "Description": "Use the Flow Playground to improve your mastery of Cadence. This is a valuable tool to use as you're learning Cadence and as you are preparing Smart Contracts for launch.",
        //     "Link": "https://play.onflow.org/local-project",
        //     "Title": "Flow Playground",
        //     "isVideo": false
        // },
        // {
        //     "Description": "With flexible learning modules suited to your needs, this go at your own pace tutorial developed by the Flow team is available for developers just getting started with Flow. Use this powerful tool to start learning cadence quickly.",
        //     "Link": "https://web3-learning-roadmap.vercel.app/",
        //     "Title": "Learn all about Cadence in 1 hour, 1 day, or 1 week",
        //     "isVideo": false
        // },
    ]

    const arrowStyles = {
        position: 'absolute',
        zIndex: 2,
        top: 'calc(50% - 15px)',
        width: 30,
        height: 30,
        cursor: 'pointer',
        background: 'darkgray',
        borderRadius: '20px'
    };


    return (
        <div className='p-10 w-full border-2 bg-slate-50' style={{minWidth: '-webkit-fill-available'}}>
            <Carousel
            showArrows={true}
            infiniteLoop={true}
            renderArrowPrev={(onClickHandler, hasPrev, label) =>
                hasPrev && (
                    <button type="button" onClick={onClickHandler} title={label} style={{ ...arrowStyles, left: 15 }}>
                        {'<-'}
                    </button>
                )
            }
            renderArrowNext={(onClickHandler, hasNext, label) =>
                hasNext && (
                    <button type="button" onClick={onClickHandler} title={label} style={{ ...arrowStyles, right: 15 }}>
                        {'->'}
                    </button>
                )
            }
            >
                <div className='py-4' style={{textAlign: '-webkit-center'}}>
                    <CarouselCardWelcome
                        image={welcome}
                        description={LCobjectDesc[0].Description}
                        title={LCobjectDesc[0].Title}
                        // link={LCobjectDesc[0].Link}
                        isVideo={LCobjectDesc[0].isVideo}
                    />
                </div>
                <div className='py-4' style={{textAlign: '-webkit-center'}}>
                    <CarouselCard 
                        image={buildonflow}
                        description={LCobjectDesc[1].Description}
                        title={LCobjectDesc[1].Title}
                        link={LCobjectDesc[1].Link}
                        isVideo={LCobjectDesc[1].isVideo}
                    />
                </div>
              <div className='py-4' style={{textAlign: '-webkit-center'}}>
                    <CarouselCard 
                        image={flowfund}
                        description={LCobjectDesc[2].Description}
                        title={LCobjectDesc[2].Title}
                        link={LCobjectDesc[2].Link}
                        isVideo={LCobjectDesc[2].isVideo}
                    />
                </div>

                  {/* 
                <div className='py-4' style={{textAlign: '-webkit-center'}}>
                    <CarouselCard 
                        image={gettingstarted}
                        description={LCobjectDesc[3].Description}
                        title={LCobjectDesc[3].Title}
                        link={LCobjectDesc[3].Link}
                        isVideo={LCobjectDesc[3].isVideo}
                    />
                </div>
                <div className='py-4' style={{textAlign: '-webkit-center'}}>
                    <CarouselCard 
                        image={gettingstarted}
                        description={LCobjectDesc[4].Description}
                        title={LCobjectDesc[4].Title}
                        link={LCobjectDesc[4].Link}
                        isVideo={LCobjectDesc[4].isVideo}
                    />
                </div>
                <div className='py-4' style={{textAlign: '-webkit-center'}}>
                    <CarouselCard 
                        image={gettingstarted}
                        description={LCobjectDesc[5].Description}
                        title={LCobjectDesc[5].Title}
                        link={LCobjectDesc[5].Link}
                        isVideo={LCobjectDesc[5].isVideo}
                    />
                </div>
                <div className='py-4' style={{textAlign: '-webkit-center'}}>
                    <CarouselCard 
                        image={gettingstarted}
                        description={LCobjectDesc[6].Description}
                        title={LCobjectDesc[6].Title}
                        link={LCobjectDesc[6].Link}
                        isVideo={LCobjectDesc[6].isVideo}
                    />
                </div> */}
            </Carousel>
    </div>
    );
}

export default LearningCadence;
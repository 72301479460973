import React from 'react';
import logo from '../flow_small_logo.png'

function Nav() {
    return (
        <div className='flex border-b-2 border-slate-100 py-3'>
            <img src={logo} className='h-10 ml-6' alt="logo" />
            <div className='flex pt-2 ml-3 text-slate-900 w-full'>
                <div className='font-mono font-bold md:text-xl ml-3'>
                    FlowToMarket
                </div>
            </div>
        </div>
    );
}

export default Nav;
import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import CarouselCard from '../../_components/CarouselCard';
// import grow from '../../../../images/grow.png'

// import dappermktg from '../../../../images/dappermktg.png'
import nftmktg from '../../../../images/nftmktg.webp'
import success from '../../../../images/success.png'
import tips from '../../../../images/tips.png'

import growcomm from '../../../../images/growcomm.png'



function BloctoIntegration(props) {

    const LCobjectDesc = [

        {
            "Description": "A guide to building an NFT project, developing your community, and pre/post-launch planning.",
            "Link": "https://drive.google.com/file/d/1HmtJfWZWdV8y7nk-5QZ6f9HERiI25Ze1/view?usp=sharing",
            "Title": "A Dapper Guide to Building NFT Communities",
            "isVideo": false
        },

        {
            "Description": "Reddit's comprehensive guide to all things NFT",
            "Link": "https://connect.redditinc.com/hubfs/ABCs-of-NFTs.pdf?hsCtaTracking=6b246fc6-81f3-4c1f-a797-5cf8a53a33cc%7Cae0a2ae7-e1f7-45e8-ad5b-f7f6a9bbcf56",
            "Title": "The ABCs of NFTs",
            "isVideo": false
        },

        {
            "Description": "The ultimate guide to NFT marketing in 2022",
            "Link": "https://growthchannel.io/blog/ultimate-guide-to-nft-marketing-7-case-studies",
            "Title": "7 Marketing Case Studies for NFTs",
            "isVideo": false
        },
        {
            "Description": "6 tips from successful launches",
            "Link": "https://medium.com/@alasadi.m1738/how-to-build-hype-for-an-nft-project-launch-6-tips-from-successful-nft-collections-841961b831dc",
            "Title": "How to build hype for you NFT launch",
            "isVideo": false
        },
       
    ]

    const arrowStyles = {
        position: 'absolute',
        zIndex: 2,
        top: 'calc(50% - 15px)',
        width: 30,
        height: 30,
        cursor: 'pointer',
        background: 'darkgray',
        borderRadius: '20px'
    };


    return (
        <div className='p-10 w-full border-2 bg-slate-50' style={{minWidth: '-webkit-fill-available'}}>
            <Carousel
            showArrows={true}
            infiniteLoop={true}
            renderArrowPrev={(onClickHandler, hasPrev, label) =>
                hasPrev && (
                    <button type="button" onClick={onClickHandler} title={label} style={{ ...arrowStyles, left: 15 }}>
                        {'<-'}
                    </button>
                )
            }
            renderArrowNext={(onClickHandler, hasNext, label) =>
                hasNext && (
                    <button type="button" onClick={onClickHandler} title={label} style={{ ...arrowStyles, right: 15 }}>
                        {'->'}
                    </button>
                )
            }
            >

            <div className='py-4' style={{textAlign: '-webkit-center'}}>
                    <CarouselCard 
                        image={growcomm}
                        description={LCobjectDesc[0].Description}
                        title={LCobjectDesc[0].Title}
                        link={LCobjectDesc[0].Link}
                        isVideo={LCobjectDesc[0].isVideo}
                    />
                </div>
                <div className='py-4' style={{textAlign: '-webkit-center'}}>
                    <CarouselCard 
                        image={tips}
                        description={LCobjectDesc[1].Description}
                        title={LCobjectDesc[1].Title}
                        link={LCobjectDesc[1].Link}
                        isVideo={LCobjectDesc[1].isVideo}
                    />
                </div>

                <div className='py-4' style={{textAlign: '-webkit-center'}}>
                    <CarouselCard 
                        image={nftmktg}
                        description={LCobjectDesc[2].Description}
                        title={LCobjectDesc[2].Title}
                        link={LCobjectDesc[2].Link}
                        isVideo={LCobjectDesc[2].isVideo}
                    />
                </div>

                <div className='py-4' style={{textAlign: '-webkit-center'}}>
                    <CarouselCard 
                        image={success}
                        description={LCobjectDesc[3].Description}
                        title={LCobjectDesc[3].Title}
                        link={LCobjectDesc[3].Link}
                        isVideo={LCobjectDesc[3].isVideo}
                    />
                </div>
            </Carousel>
    </div>
    );
}

export default BloctoIntegration;
import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import CarouselCard from '../../_components/CarouselCard';
// import postlaunch from '../../../../images/postlaunch.png'
import flowverse from '../../../../images/flowverse.png'

import flowtomkt from '../../../../images/flowtomkt.jpg'
import casestudy from '../../../../images/casestudy.png'


// import custodialGHimage from '../../../../images/custodial_github_repo.png'
// import custodialDocsimage from '../../../../images/custodial_api_docs.png'


function IntegratingCustodialWallet(props) {

    const LCobjectDesc = [
        {
            "Description": "Share your drop/project results at the form here to get added to the Flowverse leaderboard.",
            "Link": "https://docs.google.com/forms/d/e/1FAIpQLSf1ZiRMGOtccnNMWLuW1J_6Qil9hpSoxp20L-6i_zr95d0oYw/viewform",
            "Title": "Flowverse Rankings Leaderboard ",
            "isVideo": false
        },
        {
            "Description": "Want to share your story? Submit it to the Flow Case Study Form to share with the Flow community. ",
            "Link": "https://docs.google.com/forms/d/1wjCMHZ6GxA8qBWQK_KrUiMU2N1aT4joI_mtLJX-5KZQ/edit",
            "Title": "Submit a Flow Success Story",
            "isVideo": false
        },    
        {
            "Description": "Have any feedback on launching on Flow? We’re always looking for feedback and improvements on the platform. Please share your thoughts in the survey.",
            "Link": "https://docs.google.com/forms/d/1L39hA1_NuuSX8RTfcj0WP5RBflfJQ3zk2lqbl8QoFYk/",
            "Title": "Flow Partner Post Launch Survey",
            "isVideo": false
        },    
    ]

    const arrowStyles = {
        position: 'absolute',
        zIndex: 2,
        top: 'calc(50% - 15px)',
        width: 30,
        height: 30,
        cursor: 'pointer',
        background: 'darkgray',
        borderRadius: '20px'
    };


    return (
        <div className='p-10 w-full border-2 bg-slate-50' style={{minWidth: '-webkit-fill-available'}}>
            <Carousel
            showArrows={true}
            infiniteLoop={true}
            renderArrowPrev={(onClickHandler, hasPrev, label) =>
                hasPrev && (
                    <button type="button" onClick={onClickHandler} title={label} style={{ ...arrowStyles, left: 15 }}>
                        {'<-'}
                    </button>
                )
            }
            renderArrowNext={(onClickHandler, hasNext, label) =>
                hasNext && (
                    <button type="button" onClick={onClickHandler} title={label} style={{ ...arrowStyles, right: 15 }}>
                        {'->'}
                    </button>
                )
            }
            >
                <div className='py-4' style={{textAlign: '-webkit-center'}}>
                    <CarouselCard 
                        image={flowverse}
                        description={LCobjectDesc[0].Description}
                        title={LCobjectDesc[0].Title}
                        link={LCobjectDesc[0].Link}
                        isVideo={LCobjectDesc[0].isVideo}
                    />
                </div>
                <div className='py-4' style={{textAlign: '-webkit-center'}}>
                    <CarouselCard 
                        image={casestudy}
                        description={LCobjectDesc[1].Description}
                        title={LCobjectDesc[1].Title}
                        link={LCobjectDesc[1].Link}
                        isVideo={LCobjectDesc[1].isVideo}
                    />
                </div>
                <div className='py-4' style={{textAlign: '-webkit-center'}}>
                    <CarouselCard 
                        image={flowtomkt}
                        description={LCobjectDesc[2].Description}
                        title={LCobjectDesc[2].Title}
                        link={LCobjectDesc[2].Link}
                        isVideo={LCobjectDesc[2].isVideo}
                    />
                </div>
            </Carousel>
    </div>
    );
}

export default IntegratingCustodialWallet;
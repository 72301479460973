import React from 'react';
import ReactPlayer from 'react-player';
// import HomeModal from './HomeModal';

function CarouselCardWelcome({image, description, title, link, isVideo}) {
    return (
        <div className='border-2 lg:w-[800px] md:w-[400px] w-[300px] shadow-lg rounded-lg bg-white'>
            <div className='border-b-2'>
                {isVideo ?
                <ReactPlayer url={link} width='100%' height={500}/> 
                :
                <img src={image} className='lg:p-8 p-5 ' 
                // style={{height: 450, width:700}} 
                alt={`flowphoto`}
                />
                }
            </div>
            
            <div className='text-left mx-5'>
                
                <div className='text-2xl font-semibold mt-5'>
                    {title}
                </div>
                <div className='mb-5 mt-5 text-lg'>
                {description}
                </div>
                {/* <button onClick={() => window.open(link)} className='border-2 rounded-lg py-1 px-2 mb-5 bg-green-600 text-white font-semibold border-green-600 hover:bg-green-500'>
                    Visit Page
                </button> */}
            </div>
        </div>
    );
}

export default CarouselCardWelcome;
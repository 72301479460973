import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import CarouselCard from '../../_components/CarouselCard';
// import dapperRequestImage from '../../../../images/dapper_request_form.png'
// import community from '../../../../images/community.png'
import flowverse from '../../../../images/flowverse.png'
import flowversediscord from '../../../../images/flowversediscord.png'
import float from '../../../../images/float.png'
import hypeday from '../../../../images/hypeday.png'
import cryptoslam from '../../../../images/cryptoslam.png'
import nftcalendar from '../../../../images/nftcalendar.png'
// import dappermktg from '../../../../images/dappermktg.png'
import emeraldbot from '../../../../images/emeraldbot.png'
import dappradar from '../../../../images/dappradar.png'




function DapperRequest(props) {

    const LCobjectDesc = [
        {
            "Description": "Showcase new projects or drops on Flowverse.",
            "Link": "https://flowverse.co/",
            "Title": "Flowverse",
            "isVideo": false
        },
      
        {
            "Description": "After submitting your drop/project, it will be featured in the Project Announcements channel. You can also partner with the Flowverse team to host a live AMA and that will also live on Flowverse Twitter. Reach out to jamesp | Flowverse.co#2956 on Discord for questions.",
            "Link": "https://discord.gg/Krvxfmv8Dy",
            "Title": "Promote on Flowverse Discord",
            "isVideo": false
        },    

        {
            "Description": "Integrate Flow’s first proof-of-attendance protocol into your go-to-market strategy. If you want to reward your audience during a Twitter space, Discord AMA, or launch event, consider creating a FLOAT to share with your community using this form.",
            "Link": "https://floats.city/create",
            "Title": "Use FLOATS to engage your community",
            "isVideo": false
        },
       
        {
            "Description": "Add your project to NFTCalendar under Flow by submitting your project/event on this form where it will be highlighted with other blockchain projects across the industry.            ",
            "Link": "https://nftcalendar.io/events/community/add/",
            "Title": "NFT Calendar",
            "isVideo": false
        },
        {
            "Description": "Use Hype.Day to help promote your launch, event activations, and pre-and-post mint initiatives and build your community             ",
            "Link": "https://hype.day/",
            "Title": "List on Hype Day",
            "isVideo": false
        },    

        {
            "Description": "List your project on the NFT aggregator.",
            "Link": "https://docs.google.com/forms/d/e/1FAIpQLSeAbYJuEvEiGkJ5WKDR_tvZk53KverLN7abk4Ao4AfyG7R7kw/viewform",
            "Title": "List on CryptoSlam",
            "isVideo": false
        },    
        {
            "Description": "Emerald bot allows you to distribute roles and gate access to channels based on a user’s token/NFT holdings. This allows you to create things like private channels for verified holders, and other unique ways to engage with your community",
            "Link": "https://emeralddao.notion.site/emeralddao/Emerald-bot-962a1d4c22a5417baad56ba5c79fdfcd",
            "Title": "Verify NFTs on Discord with Emerald Bot",
            "isVideo": false
        },    
        {
            "Description": "Submit your project to DappRadar, a directory that supports discovery, tracking, and trade for DeFi, NFTs, and games. Your dapp will be included in the site’s rankings list.",
            "Link": "https://dappradar.com/dashboard/login?next_url=aHR0cHM6Ly9kYXBwcmFkYXIuY29tL2Rhc2hib2FyZC9sb2dpbi1yZWRpcmVjdD9uZXh0X3BhdGg9L2Rhc2hib2FyZC9zdWJtaXQtZGFwcA==",
            "Title": "List on DappRadar",
            "isVideo": false
        },    
    ]

    const arrowStyles = {
        position: 'absolute',
        zIndex: 2,
        top: 'calc(50% - 15px)',
        width: 30,
        height: 30,
        cursor: 'pointer',
        background: 'darkgray',
        borderRadius: '20px'
    };


    return (
        <div className='p-10 w-full border-2 bg-slate-50' style={{minWidth: '-webkit-fill-available'}}>
            <Carousel
            showArrows={true}
            infiniteLoop={true}
            renderArrowPrev={(onClickHandler, hasPrev, label) =>
                hasPrev && (
                    <button type="button" onClick={onClickHandler} title={label} style={{ ...arrowStyles, left: 15 }}>
                        {'<-'}
                    </button>
                )
            }
            renderArrowNext={(onClickHandler, hasNext, label) =>
                hasNext && (
                    <button type="button" onClick={onClickHandler} title={label} style={{ ...arrowStyles, right: 15 }}>
                        {'->'}
                    </button>
                )
            }
            >
                <div className='py-4' style={{textAlign: '-webkit-center'}}>
                    <CarouselCard 
                        image={flowverse}
                        description={LCobjectDesc[0].Description}
                        title={LCobjectDesc[0].Title}
                        link={LCobjectDesc[0].Link}
                        isVideo={LCobjectDesc[0].isVideo}
                    />
                </div>
           

                <div className='py-4' style={{textAlign: '-webkit-center'}}>
                    <CarouselCard 
                        image={flowversediscord}
                        description={LCobjectDesc[1].Description}
                        title={LCobjectDesc[1].Title}
                        link={LCobjectDesc[1].Link}
                        isVideo={LCobjectDesc[1].isVideo}
                    />
                </div>
                <div className='py-4' style={{textAlign: '-webkit-center'}}>
                    <CarouselCard 
                        image={float}
                        description={LCobjectDesc[2].Description}
                        title={LCobjectDesc[2].Title}
                        link={LCobjectDesc[2].Link}
                        isVideo={LCobjectDesc[2].isVideo}
                    />
                </div>
                <div className='py-4' style={{textAlign: '-webkit-center'}}>
                    <CarouselCard 
                        image={nftcalendar}
                        description={LCobjectDesc[3].Description}
                        title={LCobjectDesc[3].Title}
                        link={LCobjectDesc[3].Link}
                        isVideo={LCobjectDesc[3].isVideo}
                    />
                </div>
                <div className='py-4' style={{textAlign: '-webkit-center'}}>
                    <CarouselCard 
                        image={hypeday}
                        description={LCobjectDesc[4].Description}
                        title={LCobjectDesc[4].Title}
                        link={LCobjectDesc[4].Link}
                        isVideo={LCobjectDesc[4].isVideo}
                    />
                </div>
                <div className='py-4' style={{textAlign: '-webkit-center'}}>
                    <CarouselCard 
                        image={cryptoslam}
                        description={LCobjectDesc[5].Description}
                        title={LCobjectDesc[5].Title}
                        link={LCobjectDesc[5].Link}
                        isVideo={LCobjectDesc[5].isVideo}
                    />
                </div>

                <div className='py-4' style={{textAlign: '-webkit-center'}}>
                    <CarouselCard 
                        image={emeraldbot}
                        description={LCobjectDesc[6].Description}
                        title={LCobjectDesc[6].Title}
                        link={LCobjectDesc[6].Link}
                        isVideo={LCobjectDesc[6].isVideo}
                    />
                </div>
                <div className='py-4' style={{textAlign: '-webkit-center'}}>
                    <CarouselCard 
                        image={dappradar}
                        description={LCobjectDesc[7].Description}
                        title={LCobjectDesc[7].Title}
                        link={LCobjectDesc[7].Link}
                        isVideo={LCobjectDesc[7].isVideo}
                    />
                </div>
            </Carousel>
    </div>
    );
}

export default DapperRequest;
import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import CarouselCard from '../../_components/CarouselCard';
// import flowLogo from '../../../../Flow_Logo.png'
// import mainnetDeployImage from '../../../../images/mainnet_deploy_guide.png'
// import flowmktg from '../../../../images/flowmktg.png'
import flowecosystem from '../../../../images/flowecosystem.jpeg'
import flowtwitter from '../../../../images/flowtwitter.png'
import flowdiscord from '../../../../images/flowdiscord.png'



import logoguide from '../../../../images/logoguide.png'

function DeployContract(props) {

    const LCobjectDesc = [
        {
            "Description": " Basics on how to position and market your Flow launch and activate in your communities.",
            "Link": "https://drive.google.com/file/d/1REXP9h1JSBmFDXbppFSyfAIITikXFkVF/view?usp=sharing",
            "Title": "Flow Launch Playbook",
            "isVideo": false
        },
        {
            "Description": "Use the hashtag #onFlow and/or tag @flow_blockchain for retweet and social amplification.",
            "Link": "https://twitter.com/flow_blockchain",
            "Title": "Flow Twitter",
            "isVideo": false
        },
        {
            "Description": "Share your drop or project with our Flow Discord community in the #talk-projects-on-flow channel ",
            "Link": "https://discord.gg/XTGYfEuJ",
            "Title": "Flow Discord",
            "isVideo": false
        },
        {
            "Description": "Utilize the Flow logo on your website ",
            "Link": "https://drive.google.com/drive/folders/1v-CCovCOt97LohGd3th_UOwuPOWsvpf1?usp=sharing",
            "Title": "Flow Logos & Guidelines",
            "isVideo": false
        }
        
    ]

    const arrowStyles = {
        position: 'absolute',
        zIndex: 2,
        top: 'calc(50% - 15px)',
        width: 30,
        height: 30,
        cursor: 'pointer',
        background: 'darkgray',
        borderRadius: '20px'
    };


    return (
        <div className='p-10 w-full border-2 bg-slate-50' style={{minWidth: '-webkit-fill-available'}}>
            <Carousel
            showArrows={true}
            infiniteLoop={true}
            renderArrowPrev={(onClickHandler, hasPrev, label) =>
                hasPrev && (
                    <button type="button" onClick={onClickHandler} title={label} style={{ ...arrowStyles, left: 15 }}>
                        {'<-'}
                    </button>
                )
            }
            renderArrowNext={(onClickHandler, hasNext, label) =>
                hasNext && (
                    <button type="button" onClick={onClickHandler} title={label} style={{ ...arrowStyles, right: 15 }}>
                        {'->'}
                    </button>
                )
            }
            >
                <div className='py-4' style={{textAlign: '-webkit-center'}}>
                    <CarouselCard 
                        image={flowecosystem}
                        description={LCobjectDesc[0].Description}
                        title={LCobjectDesc[0].Title}
                        link={LCobjectDesc[0].Link}
                        isVideo={LCobjectDesc[0].isVideo}
                    />
                </div>
                <div className='py-4' style={{textAlign: '-webkit-center'}}>
                    <CarouselCard 
                        image={flowtwitter}
                        description={LCobjectDesc[1].Description}
                        title={LCobjectDesc[1].Title}
                        link={LCobjectDesc[1].Link}
                        isVideo={LCobjectDesc[1].isVideo}
                    />
                </div>
                <div className='py-4' style={{textAlign: '-webkit-center'}}>
                    <CarouselCard 
                        image={flowdiscord}
                        description={LCobjectDesc[2].Description}
                        title={LCobjectDesc[2].Title}
                        link={LCobjectDesc[2].Link}
                        isVideo={LCobjectDesc[2].isVideo}
                    />
                </div>
                <div className='py-4' style={{textAlign: '-webkit-center'}}>
                    <CarouselCard 
                        image={logoguide}
                        description={LCobjectDesc[3].Description}
                        title={LCobjectDesc[3].Title}
                        link={LCobjectDesc[3].Link}
                        isVideo={LCobjectDesc[3].isVideo}
                    />
                </div>
            </Carousel>
    </div>
    );
}

export default DeployContract;
import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import CarouselCard from '../../_components/CarouselCard';
// import flowLogo from '../../../../Flow_Logo.png'
// import dappermktg from '../../../../images/dappermktg.png'
import dapperdigest from '../../../../images/dapperdigest.png'
import sitelogo from '../../../../images/sitelogo.png'
import discord from '../../../../images/discord.png'
import CarouselCardWelcome from '../../_components/CarouselCardWelcome';

import dapperlogo from '../../../../images/dapperlogo.png'





function MintNFT(props) {

    const LCobjectDesc = [

        {
            "Description": "The resources in this section are for Dapper Wallet partners only.",
            "Link": "https://blog.meetdapper.com/posts/dapper-digest-june",
            "Title": "Resources for Dapper Wallet Partners",
            "isVideo": false
        },
       
        {
            "Description": "Your project will be included in the monthly newsletter and publication on the Dapper blog.",
            "Link": "https://blog.meetdapper.com/posts/dapper-digest-june",
            "Title": "Monthly Dapper Digest",
            "isVideo": false
        },
        {
            "Description": "You will have a dedicated channel within our Dapper Discord community.",
            "Link": "https://discord.gg/vQEFMjsT",
            "Title": "Channel in Dapper Discord",
            "isVideo": false
        },
        {
            "Description": "Your brand logo will be displayed on our Dapper website.",
            "Link": "https://www.meetdapper.com/developers",
            "Title": "Logo on our Website",
            "isVideo": false
        }
        
    ]

    const arrowStyles = {
        position: 'absolute',
        zIndex: 2,
        top: 'calc(50% - 15px)',
        width: 20,
        height: 30,
        cursor: 'pointer',
        background: 'darkgray',
        borderRadius: '20px'
    };


    return (
        <div className='p-10 w-full border-2 bg-slate-50' style={{minWidth: '-webkit-fill-available'}}>
            <Carousel
            showArrows={true}
            infiniteLoop={true}
            renderArrowPrev={(onClickHandler, hasPrev, label) =>
                hasPrev && (
                    <button type="button" onClick={onClickHandler} title={label} style={{ ...arrowStyles, left: 0 }}>
                        {'<-'}
                    </button>
                )
            }
            renderArrowNext={(onClickHandler, hasNext, label) =>
                hasNext && (
                    <button type="button" onClick={onClickHandler} title={label} style={{ ...arrowStyles, right: 15 }}>
                        {'->'}
                    </button>
                )
            }
            >
                <div className='py-4' style={{textAlign: '-webkit-center'}}>
                    <CarouselCardWelcome
                        image={dapperlogo}
                        description={LCobjectDesc[0].Description}
                        title={LCobjectDesc[0].Title}
                        link={LCobjectDesc[0].Link}
                        isVideo={LCobjectDesc[0].isVideo}
                    />
                </div>
                <div className='py-4' style={{textAlign: '-webkit-center'}}>
                    <CarouselCard 
                        image={dapperdigest}
                        description={LCobjectDesc[1].Description}
                        title={LCobjectDesc[1].Title}
                        link={LCobjectDesc[1].Link}
                        isVideo={LCobjectDesc[1].isVideo}
                    />
                </div>
                <div className='py-4' style={{textAlign: '-webkit-center'}}>
                    <CarouselCard 
                        image={discord}
                        description={LCobjectDesc[2].Description}
                        title={LCobjectDesc[2].Title}
                        link={LCobjectDesc[2].Link}
                        isVideo={LCobjectDesc[2].isVideo}
                    />
                </div>
                <div className='py-4' style={{textAlign: '-webkit-center'}}>
                    <CarouselCard 
                        image={sitelogo}
                        description={LCobjectDesc[3].Description}
                        title={LCobjectDesc[3].Title}
                        link={LCobjectDesc[3].Link}
                        isVideo={LCobjectDesc[3].isVideo}
                    />
                </div>
            </Carousel>
    </div>
    );
}

export default MintNFT;
import './App.css';
import Nav from './components/Nav';
import ModulesNav from './components/Page_Components/Modules_Nav/ModulesNav';
// import HomeModal from './components/Page_Components/_components/HomeModal';

function App() {


  return (
    <div className="App">
            <Nav />
            {/* <HomeModal /> */}
            <ModulesNav />
    </div>
  );
}

export default App;

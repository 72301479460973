import React from 'react';
import { Link, Routes, Route, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChalkboard, faGlasses, faWallet, faMoneyCheck } from '@fortawesome/free-solid-svg-icons';
import {  faOctopusDeploy, faSuperpowers, faGoogleWallet, faConnectdevelop, } from '@fortawesome/free-brands-svg-icons';

// import { faChalkboard, faGlasses, faMoneyCheck, faTools, faWallet } from '@fortawesome/free-solid-svg-icons';
// import { , faDiscord, faGoogleWallet, faOctopusDeploy, faSuperpowers } from '@fortawesome/free-brands-svg-icons';

// import { faFile } from '@fortawesome/free-regular-svg-icons';
import ParticlesComponent from '../_components/ParticlesComponent';
import LearningCadence from '../Modules/Learning_Cadence/LearningCadence';
import DeployContract from '../Modules/Deploy_Contract/DeployContract';
import MintNFT from '../Modules/Mint_NFT/MintNFT';
import IntegratingCustodialWallet from '../Modules/Integrating_Custodial_Wallet/IntegratingCustodialWallet';
// import FlowEcosystemTools from '../Modules/Flow_Ecosystem_Tools/FlowEcosystemTools';
// import FlowDevDocs from '../Modules/Flow_Dev_Docs/FlowDevDocs';
import BuyingFlow from '../Modules/Buying_Flow/BuyingFlow';
import BloctoIntegration from '../Modules/Blocto_Integration/BloctoIntegration';
import DapperRequest from '../Modules/Dapper_Request/DapperRequest';
// import EthToFlow from '../Modules/Eth_To_Flow/EthToFlow';


function ModulesNav(props) {

    const router = useLocation()

    return (
        <div className='md:grid md:grid-cols-5 '>
            <div className='lg:col-span-1 md:col-span-2'>
                <div className='border-r-2 text-left pl-10 pr-5 pb-10'>
                    <Link to='/gettingstarted' >
                        <div className={`pt-5 font-mono font-semibold text-[16px] hover:text-green-500 cursor-pointer active:text-green-500 ${router.pathname === "/gettingstarted" ? 'text-green-500 font-bold underline underline-offset-4' : 'text-gray-800'}`}>
                            <FontAwesomeIcon icon={faChalkboard} className='mr-2 text-green-400'/> Getting Started
                        </div>
                    </Link>
                    <Link to='/flowmarketingchannels'>
                    <div className={`pt-5 font-mono font-semibold text-[16px] hover:text-green-500 cursor-pointer active:text-green-500 ${router.pathname === "/flowmarketingchannels" ? 'text-green-500 font-bold underline underline-offset-4' : 'text-gray-800'}`}>
                            <FontAwesomeIcon icon={faOctopusDeploy} className='mr-2 text-blue-400'/> Flow Marketing Channels
                        </div>
                    </Link>
                    <Link to='/dappermarketingchannels'>
                        <div className={`pt-5 font-mono font-semibold text-[16px] hover:text-green-500 cursor-pointer active:text-green-500 ${router.pathname === "/dappermarketingchannels" ? 'text-green-500 font-bold underline underline-offset-4' : 'text-gray-800'}`}>
                            <FontAwesomeIcon icon={faSuperpowers} className='mr-2 text-yellow-400'/> Dapper Marketing Channels
                        </div>
                    </Link>
                    <Link to='/communityrunresources'>
                        <div className={`pt-5 font-mono font-semibold text-[16px] hover:text-green-500 cursor-pointer active:text-green-500 ${router.pathname === "/communityrunresources" ? 'text-green-500 font-bold underline underline-offset-4' : 'text-gray-800'}`}>
                            <FontAwesomeIcon icon={faWallet} className='mr-2 text-pink-400'/> Community Run Resources
                        </div>
                    </Link>
                
                    <Link to='/growcommunity'>
                        <div className={`pt-5 font-mono font-semibold text-[16px] hover:text-green-500 cursor-pointer active:text-green-500 ${router.pathname === "/growcommunity" ? 'text-green-500 font-bold underline underline-offset-4' : 'text-gray-800'}`}>
                            <FontAwesomeIcon icon={faGlasses} className='mr-2 text-teal-400'/> Grow Your Community Guides
                        </div>
                    </Link>

                        <Link to='/postlaunch'>
                        <div className={`pt-5 font-mono font-semibold text-[16px] hover:text-green-500 cursor-pointer active:text-green-500 ${router.pathname === "/postlaunch" ? 'text-green-500 font-bold underline underline-offset-4' : 'text-gray-800'}`}>
                            <FontAwesomeIcon icon={faGoogleWallet} className='mr-2 text-indigo-400'/> Post-Launch Resources
                        </div>
                    </Link>

                

                    
                    <Link to='/contact'>
                        <div className={`pt-5 font-mono font-semibold text-[16px] hover:text-green-500 cursor-pointer active:text-green-500 ${router.pathname === "/contact" ? 'text-green-500 font-bold underline underline-offset-4' : 'text-gray-800'}`}>
                            <FontAwesomeIcon icon={faMoneyCheck} className='mr-2 text-blue-800'/> Connect With Flow
                        </div>
                    </Link>

                    <a target="_blank" rel="noreferrer" href='https://share.hsforms.com/17cCbyaegSXWkNPWzhfWamA3u4gy'>
                        <div className={`pt-5 font-mono font-semibold text-[16px] hover:text-green-500 cursor-pointer  'text-gray-800'}`}>
                            <FontAwesomeIcon icon={faConnectdevelop} className='mr-2 text-yellow-800'/> Contact Us
                        </div>
                    </a>

                      {/* 
                    <Link to='/flowecosystemtools'>
                        <div className={`pt-5 font-mono font-semibold text-[16px] hover:text-green-500 cursor-pointer active:text-green-500 ${router.pathname === "/flowecosystemtools" ? 'text-green-500 font-bold underline underline-offset-4' : 'text-gray-800'}`}>
                            <FontAwesomeIcon icon={faTools} className='mr-2 text-slate-500'/> Flow Ecosystem Tools
                        </div>
                    </Link>
                    <Link to='/flowdeveloperdocs'>
                        <div className={`pt-5 font-mono font-semibold text-[16px] hover:text-green-500 cursor-pointer active:text-green-500 ${router.pathname === "/flowdeveloperdocs" ? 'text-green-500 font-bold underline underline-offset-4' : 'text-gray-800'}`}>
                            <FontAwesomeIcon icon={faFile} className='mr-2 text-red-700'/> Official Flow Developer Docs
                        </div>
                    </Link>
                        <div onClick={() => window.open("https://discord.gg/njx3CVcC8p")} className={`pt-5 font-mono font-semibold text-[16px] hover:text-green-500 cursor-pointer active:text-green-500`}>
                            <FontAwesomeIcon icon={faDiscord} className='mr-2 text-purple-700'/> Flow Discord For FAQ's
                        </div> */}
                    {/* add discord link here */}

                </div>
            </div>
            <div className='grid md:col-span-3 lg:col-span-4' style={{minWidth: '-webkit-fill-available'}}>
                <ParticlesComponent />
                <Routes>
                    <Route path="/" exact element={<LearningCadence />} />
                    <Route path="/gettingstarted" exact element={<LearningCadence />} />
                    <Route path="/flowmarketingchannels" exact element={<DeployContract />} />
                    <Route path="/dappermarketingchannels" exact element={<MintNFT />} />
                    <Route path="/communityrunresources" exact element={<DapperRequest />}/>
                    <Route path="/growcommunity" exact element={<BloctoIntegration />}/>
                    <Route path="/postlaunch" exact element={<IntegratingCustodialWallet />}/>
                    <Route path="/contact" exact element={<BuyingFlow />}/>

                    {/* 
                    <Route path="/flowecosystemtools" exact element={<FlowEcosystemTools />}/>
                    <Route path="/flowdeveloperdocs" exact element={<FlowDevDocs />}/>
                    <Route path="/ethtoflowbridge" exact element={<EthToFlow />}/> */}
                </Routes>
            </div>
        </div>
    );
}

export default ModulesNav;
import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import CarouselCard from '../../_components/CarouselCard';
// import buyFlowImage from '../../../../images/how_to_buy_flow.png'

// import contactflow from '../../../../images/contactflow.png'
import launch from '../../../../images/launch.png'



function BuyingFlow(props) {

    const LCobjectDesc = [
        {
            "Description": "Have any additional questions or feedback? Let us know via the Flow Contact Form.",
            "Link": "https://share.hsforms.com/17cCbyaegSXWkNPWzhfWamA3u4gy",
            "Title": "Flow Contact Form",
            "isVideo": false
        },

        {
            "Description": "Have a project that needs additional marketing support or are you planning your future launch? Fill out the Launch on Flow form with your launch details and we’ll be in touch.",
            "Link": "https://docs.google.com/forms/d/e/1FAIpQLSdAJMmbymQaUCJjovcC3SCf3ozpwlngAysClmDXtETpc9CCZw/viewform",
            "Title": "Launch on Flow form",
            "isVideo": false
        },
    ]

    const arrowStyles = {
        position: 'absolute',
        zIndex: 2,
        top: 'calc(50% - 15px)',
        width: 30,
        height: 30,
        cursor: 'pointer',
        background: 'darkgray',
        borderRadius: '20px'
    };


    return (
        <div className='p-10 w-full border-2 bg-slate-50' style={{minWidth: '-webkit-fill-available'}}>
            <Carousel
            showArrows={true}
            infiniteLoop={true}
            renderArrowPrev={(onClickHandler, hasPrev, label) =>
                hasPrev && (
                    <button type="button" onClick={onClickHandler} title={label} style={{ ...arrowStyles, left: 15 }}>
                        {'<-'}
                    </button>
                )
            }
            renderArrowNext={(onClickHandler, hasNext, label) =>
                hasNext && (
                    <button type="button" onClick={onClickHandler} title={label} style={{ ...arrowStyles, right: 15 }}>
                        {'->'}
                    </button>
                )
            }
            >
                {/* <div className='py-4' style={{textAlign: '-webkit-center'}}>
                    <CarouselCard 
                        image={contactflow}
                        description={LCobjectDesc[0].Description}
                        title={LCobjectDesc[0].Title}
                        link={LCobjectDesc[0].Link}
                        isVideo={LCobjectDesc[0].isVideo}
                    />
                </div> */}

                <div className='py-4' style={{textAlign: '-webkit-center'}}>
                    <CarouselCard 
                        image={launch}
                        description={LCobjectDesc[1].Description}
                        title={LCobjectDesc[1].Title}
                        link={LCobjectDesc[1].Link}
                        isVideo={LCobjectDesc[1].isVideo}
                    />
                </div>
            </Carousel>
    </div>
    );
}

export default BuyingFlow;